<template>
    <div class="success-content">
        <img src="../../../assets/image/success.png" alt="">
        <div class="success-text">创建成功！</div>
        <el-button @click="goBack">返回</el-button>
    </div>
</template>

<script>
    export default {
        name: "successPage",
        methods: {
            goBack() {
                this.$emit('stepActiveAdd', true)
            }
        }
    }
</script>

<style scoped lang="scss">
    .success-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .success-text {
            margin-top: 48px;
            font-size: 24px;
            color: #060111;
        }

        .el-button {
            margin-top: 44px;
        }
    }
</style>