<template>
    <div class="ad-content">
        <el-scrollbar ref="scroll">
            <el-breadcrumb class="genera-breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>直播实训题管理</el-breadcrumb-item>
                <el-breadcrumb-item>广告投放</el-breadcrumb-item>
                <el-breadcrumb-item>创建广告数据</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="steps-wrapper">
                <div class="step-item" :class="{current: [1,2,3,4].includes(stepActive)}">
                    <div class="step-line"></div>
                    <div class="step-text">1</div>
                    <div class="step-title">广告投放基本信息</div>
                </div>
                <div class="step-item" :class="{current: [2,3,4].includes(stepActive)}">
                    <div class="step-line"></div>
                    <div class="step-text">2</div>
                    <div class="step-title">添加广告投放数据</div>
                </div>
                <!--                <div class="step-item" :class="{current: [3,4].includes(stepActive)}">-->
                <!--                    <div class="step-line"></div>-->
                <!--                    <div class="step-text">3</div>-->
                <!--                    <div class="step-title">添加竞争店铺</div>-->
                <!--                </div>-->
                <div class="step-item" :class="{current: [3,4].includes(stepActive)}">
                    <div class="step-line"></div>
                    <div class="step-text">3</div>
                    <div class="step-title">创建成功</div>
                </div>
            </div>
            <div class="data-form" v-if="showSub">
                <adLaunchInfo v-if="stepActive == 1" ref="adLaunchInfo" @stepActiveAdd="stepActiveAdd"
                              :stepOneData="stepOneData" @getTotalTime="getTotalTime"></adLaunchInfo>
                <addLaunchData v-if="stepActive == 2 && !isGenerateData" ref="addLaunchData" :stepOneData="stepOneData"
                               :stepTwoData="stepTwoData"
                               @stepActiveAdd="stepActiveAdd"></addLaunchData>
                <generateData v-if="stepActive == 2 && isGenerateData" ref="generateData" :stepThreeData="stepThreeData"
                              @stepActiveAdd="stepActiveAdd"></generateData>
                <successPage v-if="stepActive == 3" @stepActiveAdd="stepActiveAdd"></successPage>
            </div>
        </el-scrollbar>
        <div class="footer-content" v-if="stepActive != 3">
            <el-button v-if="stepActive === 1" @click="toBack">返回</el-button>
            <el-button @click="lastStep" v-if="stepActive !== 1 && stepActive !==4">上一步</el-button>
            <el-button type="primary" v-if="stepActive !== 4 && (stepActive !== 2 || isGenerateData)" @click="nextStep">
                下一步
            </el-button>
            <el-button type="primary" v-if="stepActive == 2 && !isGenerateData" @click="getData">生成数据</el-button>
        </div>
    </div>
</template>

<script>
    import adLaunchInfo from "@/components/admin/adLaunch/adLaunchInfo";
    import addLaunchData from "@/components/admin/adLaunch/addLaunchData";
    import generateData from "@/components/admin/adLaunch/generateData";
    import successPage from "@/components/admin/adLaunch/successPage";
    import {advertising_generateData, advertising_add, advertising_detail} from '@/utils/apis'

    export default {
        name: "CreateAdLaunch",
        components: {
            adLaunchInfo,
            addLaunchData,
            generateData,
            successPage
        },
        data() {
            return {
                stepActive: 1,
                // 第一步的数据
                stepOneData: null,
                // 第二步的数据
                stepTwoData: null,
                // 第三步的数据
                stepThreeData: null,
                isGenerateData: false,
                editId: null,
                showSub: true,
            }
        },
        created() {
            this.editId = this.$route.query.id ? this.$route.query.id : null
            if (this.editId) {
                this.showSub = false
                this.getEdit()
            } else {
                let adLaunchData = JSON.parse(localStorage.getItem('adLaunchData'))
                if (adLaunchData) {
                    this.stepActive = adLaunchData.currentStep ? adLaunchData.currentStep : 1
                    this.isGenerateData = adLaunchData.isGenerateData
                    this.stepOneData = adLaunchData.stepOneData ? adLaunchData.stepOneData : null
                    this.stepTwoData = adLaunchData.stepTwoData ? adLaunchData.stepTwoData : null
                    this.stepThreeData = adLaunchData.stepThreeData ? adLaunchData.stepThreeData : null
                    this.stepThreeData = adLaunchData.stepThreeData ? adLaunchData.stepThreeData : null
                }
            }
        },
        methods: {
            getEdit() {
                let param = {
                    advertising_id: this.editId
                }
                advertising_detail(param).then(res => {
                    let stepOneData = {
                        name: res.data.name,
                        category_id: res.data.category_id,
                        script_id: res.data.script_id,
                        money: res.data.money,
                        selectCate: res.data.category_id_arr
                    }
                    this.stepOneData = stepOneData
                    let activity_time_config = JSON.parse(res.data.activity_time_config)
                    let region_config = JSON.parse(res.data.region_config)
                    let age_config = JSON.parse(res.data.age_config)
                    let sex_config = JSON.parse(res.data.sex_config)
                    let industry_config = JSON.parse(res.data.industry_config)
                    let net_config = JSON.parse(res.data.net_config)
                    let phone_config = JSON.parse(res.data.phone_config)
                    let crowd_config = JSON.parse(res.data.crowd_config)
                    let first_proportion = JSON.parse(res.data.first_proportion)
                    let stepTwoData = [
                        {
                            title: '活动时间',
                            hiddenTrans: true,
                            skipRatio: true,
                            list: [
                                {
                                    client_num_start: void 0,
                                    client_num_end: void 0,
                                    proportion: activity_time_config.proportion,
                                    wave_range_start: activity_time_config.left,
                                    wave_range_end: activity_time_config.right,
                                }
                            ],
                        },
                        {
                            title: '地区设置',
                            hiddenWave: true,
                            calcRatio: true,
                            list: [
                                {
                                    title: '一线城市',
                                    proportion: region_config.first_city,
                                    wave_range_start: void 0,
                                    wave_range_end: void 0,
                                    trans_rate: first_proportion.region_first_proportion,
                                },
                                {
                                    title: '新一线城市',
                                    proportion: region_config.new_first_city,
                                    wave_range_start: void 0,
                                    wave_range_end: void 0,
                                },
                                {
                                    title: '二线城市',
                                    proportion: region_config.second_city,
                                    wave_range_start: void 0,
                                    wave_range_end: void 0,
                                },
                                {
                                    title: '三线城市',
                                    proportion: region_config.third_city,
                                    wave_range_start: void 0,
                                    wave_range_end: void 0,
                                },
                                {
                                    title: '四线城市',
                                    proportion: region_config.fourth_city,
                                    wave_range_start: void 0,
                                    wave_range_end: void 0,
                                },
                                {
                                    title: '五线城市',
                                    proportion: region_config.fifth_city,
                                    wave_range_start: void 0,
                                    wave_range_end: void 0,
                                },
                            ],
                        },
                        {
                            title: '年龄设置',
                            hiddenWave: true,
                            calcRatio: true,
                            list: [],
                        },
                        {
                            title: '性别设置',
                            hiddenWave: true,
                            calcRatio: true,
                            list: [
                                {
                                    title: '男',
                                    proportion: sex_config['man'],
                                    trans_rate: first_proportion.sex_first_proportion,
                                },
                                {
                                    title: '女',
                                    proportion: sex_config['woman'],
                                },
                            ],
                        },
                        {
                            title: '行为兴趣设置',
                            skipRatio: true,
                            list: [
                                {
                                    title: '行为兴趣',
                                    proportion: industry_config.proportion,
                                    trans_rate: industry_config.conversion_percent,
                                    wave_range_start: industry_config.left,
                                    wave_range_end: industry_config.right,
                                },
                            ],
                        },
                        {
                            title: '网络设置',
                            calcRatio: true,
                            hiddenWave: true,
                            list: [
                                {
                                    title: 'WIFI',
                                    proportion: net_config['wifi'],
                                    trans_rate: first_proportion.net_first_proportion,
                                },
                                {
                                    title: '2G',
                                    proportion: net_config['2g'],
                                },
                                {
                                    title: '3G',
                                    proportion: net_config['3g'],
                                },
                                {
                                    title: '4G',
                                    proportion: net_config['4g'],
                                },
                            ],
                        },
                        {
                            title: '设备设置',
                            calcRatio: true,
                            hiddenWave: true,
                            list: [
                                {
                                    title: 'ios',
                                    proportion: phone_config['ios'],
                                    trans_rate: first_proportion.phone_first_proportion,
                                },
                                {
                                    title: '安卓',
                                    proportion: phone_config['android'],
                                },
                            ],
                        },
                        {
                            title: '更多人群设置',
                            hiddenWave: true,
                            calcRatio: true,
                            list: [
                                {
                                    title: '高订单取消/退货人群',
                                    proportion: crowd_config['high_order'],
                                    trans_rate: first_proportion.crowd_first_proportion,
                                },
                                {
                                    title: '高关注度人群',
                                    proportion: crowd_config['high_profile'],
                                },
                                {
                                    title: '促销敏感人群',
                                    proportion: crowd_config['promotion_sensitive'],
                                },
                                {
                                    title: '高活跃人群',
                                    proportion: crowd_config['high_active'],
                                }, {
                                    title: '抖音号粉丝',
                                    proportion: crowd_config['tiktok_fans'],
                                },

                            ]
                        }
                    ]
                    age_config.forEach((item, index) => {
                        let arr = item.name.split('-')
                        let obj = {
                            wave_range_start: arr[0],
                            wave_range_end: arr[1],
                            proportion: item.proportion
                        }
                        if (index == 0) {
                            obj.trans_rate = first_proportion.age_first_proportion
                        }
                        stepTwoData[2].list.push(obj)
                    })
                    this.stepTwoData = stepTwoData
                    this.showSub = true
                })
            },
            getTotalTime(val) {
                this.stepOneData = {
                    total_time: val
                }
            },
            stepActiveAdd(data) {
                let obj = {}
                if (this.stepActive === 1) {
                    this.stepOneData = data;
                    this.stepActive++;
                    obj.stepOneData = data
                    obj.currentStep = 2
                    obj.isGenerateData = false
                    localStorage.setItem('adLaunchData', JSON.stringify(obj))
                } else if (this.stepActive === 2 && !this.isGenerateData) {
                    this.stepTwoData = data;
                    this.generateData()
                } else if (this.stepActive === 3) {
                    this.toBack()
                }
            },
            toBack() {
                this.$router.push('/adLaunch/index')
            },
            lastStep() {
                if (this.stepActive === 2 && this.isGenerateData) {
                    this.isGenerateData = false
                    let obj = {
                        stepOneData: this.stepOneData,
                        stepTwoData: this.stepOneData,
                        stepThreeData: this.stepThreeData,
                        currentStep: 2,
                        isGenerateData: false
                    }
                    localStorage.setItem('adLaunchData', JSON.stringify(obj))
                    return;
                } else if (this.stepActive === 2 && !this.isGenerateData) {
                    let obj = {
                        stepOneData: this.stepOneData,
                        stepTwoData: this.stepTwoData,
                        stepThreeData: this.stepThreeData,
                        currentStep: 1,
                        isGenerateData: false
                    }
                    localStorage.setItem('adLaunchData', JSON.stringify(obj))
                }
                this.stepActive--
            },
            nextStep() {
                if (this.stepActive == 1) {
                    this.$refs.adLaunchInfo.confirmForm()
                } else if (this.stepActive == 2 && this.isGenerateData) {
                    this.mainConfirm()
                }
            },
            getData() {
                this.$refs.addLaunchData.confirmForm()
                this.$refs['scroll'].wrap.scrollTop = 0
            },
            generateData() {
                let formData = new FormData()
                let activity_time_config = {
                    script_id: this.stepOneData.script_id,
                    proportion: this.stepTwoData[0].list[0].proportion,
                    left: this.stepTwoData[0].list[0].wave_range_start,
                    right: this.stepTwoData[0].list[0].wave_range_end,
                }
                let region_config = {
                    first_city: this.stepTwoData[1].list[0].proportion,
                    new_first_city: this.stepTwoData[1].list[1].proportion,
                    second_city: this.stepTwoData[1].list[2].proportion,
                    third_city: this.stepTwoData[1].list[3].proportion,
                    fourth_city: this.stepTwoData[1].list[4].proportion,
                    fifth_city: this.stepTwoData[1].list[5].proportion,
                }
                let age_config = []
                this.stepTwoData[2].list.forEach(item => {
                    let obj = {
                        name: item.wave_range_start + '-' + item.wave_range_end,
                        proportion: item.proportion
                    }
                    age_config.push(obj)
                })
                let sex_config = {
                    man: this.stepTwoData[3].list[0].proportion,
                    woman: this.stepTwoData[3].list[1].proportion,
                }
                let industry_config = {
                    proportion: this.stepTwoData[4].list[0].proportion,
                    left: this.stepTwoData[4].list[0].wave_range_start,
                    right: this.stepTwoData[4].list[0].wave_range_end,
                    conversion_percent: this.stepTwoData[4].list[0].trans_rate,
                }
                let net_config = {
                    'wifi': this.stepTwoData[5].list[0].proportion,
                    '2g': this.stepTwoData[5].list[1].proportion,
                    '3g': this.stepTwoData[5].list[2].proportion,
                    '4g': this.stepTwoData[5].list[3].proportion,
                }
                let phone_config = {
                    ios: this.stepTwoData[6].list[0].proportion,
                    android: this.stepTwoData[6].list[1].proportion,
                }
                let crowd_config = {
                    high_order: this.stepTwoData[7].list[0].proportion,
                    high_profile: this.stepTwoData[7].list[1].proportion,
                    promotion_sensitive: this.stepTwoData[7].list[2].proportion,
                    high_active: this.stepTwoData[7].list[3].proportion,
                    tiktok_fans: this.stepTwoData[7].list[4].proportion,
                }
                let first_proportion = {
                    region_first_proportion: this.stepTwoData[1].list[0].trans_rate,
                    age_first_proportion: this.stepTwoData[2].list[0].trans_rate,
                    sex_first_proportion: this.stepTwoData[3].list[0].trans_rate,
                    net_first_proportion: this.stepTwoData[5].list[0].trans_rate,
                    phone_first_proportion: this.stepTwoData[6].list[0].trans_rate,
                    crowd_first_proportion: this.stepTwoData[7].list[0].trans_rate,
                }
                formData.append('activity_time_config', JSON.stringify(activity_time_config))
                formData.append('region_config', JSON.stringify(region_config))
                formData.append('age_config', JSON.stringify(age_config))
                formData.append('sex_config', JSON.stringify(sex_config))
                formData.append('industry_config', JSON.stringify(industry_config))
                formData.append('net_config', JSON.stringify(net_config))
                formData.append('phone_config', JSON.stringify(phone_config))
                formData.append('crowd_config', JSON.stringify(crowd_config))
                formData.append('first_proportion', JSON.stringify(first_proportion))
                advertising_generateData(formData).then(res => {
                    this.stepThreeData = res.data
                    this.isGenerateData = !this.isGenerateData;
                    let obj = {
                        stepOneData: this.stepOneData,
                        stepTwoData: this.stepOneData,
                        stepThreeData: res.data,
                        currentStep: 2,
                        isGenerateData: true
                    }
                    localStorage.setItem('adLaunchData', JSON.stringify(obj))
                })
            },
            mainConfirm() {
                let advertising = {
                    name: this.stepOneData.name,
                    category_id: this.stepOneData.category_id,
                    script_id: this.stepOneData.script_id,
                    money: this.stepOneData.money,
                }
                let unique_code = this.stepThreeData.unique_code
                let formData = new FormData()
                formData.append('advertising', JSON.stringify(advertising))
                formData.append('unique_code', unique_code)
                if (this.editId) {
                    formData.append('advertising_id', this.editId)
                }
                advertising_add(formData).then(res => {
                    this.$message.success(res.msg)
                    this.stepActive++;
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .ad-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .el-scrollbar {
            margin: 20px;
            height: calc(100% - 40px);
        }

        ::v-deep .el-scrollbar__wrap {
            height: 100%;
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }

    .steps-wrapper {
        margin: 20px 0 10px;
    }

    .data-form {
        flex: 1;
        height: 1%;
    }

    .footer-content {
        text-align: center;
        margin: 20px 0;
    }
</style>